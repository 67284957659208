import { useState } from "react";
import { useNavigate } from "react-router-dom";


import logo from '../assets/img/CSLabLogo.png'

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState("");

  const navigate  = useNavigate();

  const handleSubmit = async () => {
    const url = "https://cslabsapi-b05a5c3c41f0.herokuapp.com/cslabs/v1/auth";
    const requestBody = {
      email: username,
      password: password,
      role: (user === 'teacher') ? true : false
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        mode: "cors",
        credentials: "include",
        body: JSON.stringify(requestBody)
      });

      if (response.ok) {
        const data = await response.json();
        // Assuming the API returns a token upon successful login
        console.log(data);
        alert("Login successful!")
        const token = data;
        // Store the token in local storage or state, depending on your implementation
        // For example:
        localStorage.setItem("token", token);
        // Redirect to the dashboard page
        navigate("/dashboard");
      } else {
        console.error("Login failed");
        // console.log(await response.JSON)
        const data = await response.json();
        alert(data.err);
        // Handle login error, display error message, etc.
      }
    } catch (error) {
      console.error("Login error:", error);

      // Handle network error or other exceptions
    }
  };


  return (
    <div className="row g-0 auth-wrapper">
      <div className="col-12 col-md-7 col-lg-4 auth-main-col text-center">
        <div className='d-flex justify-content-start float-left px-4 my-5'>
          <img src={logo} className='logo' alt="csLabsLogo" />
       </div>
        <div className="d-flex flex-column align-content-end">
          <div className="auth-body mx-auto">
            <p className='text-white fs-8'>Login below to get started</p>
            <div className="auth-form-container text-start">
              <form className="auth-form" autoComplete={"off"}>
                <div className="email mb-3">
                <label htmlFor="username" className='form-label px-1'>Username</label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    name="username"
                    value={username}
                    placeholder="Username"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>

                <div className="password mb-3">
                  <div className="email mb-3">
                  <label htmlFor="password" className='form-label px-1'>Password</label>
                    <input
                    type='password'
                      className="form-control"
                      name="password"
                      id="password"
                      value={password}
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="email mb-3">
                <label htmlFor="usertype" className="form-label px-1">Select User</label>
                <select  value={user} onChange={(e) => setUser(e.target.value)} className="form-select btn-yellow text-black py-3 fs-8" aria-label="Default select example">
                  <option>Select User</option>
                  <option value="teacher">Teacher</option>
                  <option value="school-admin">School Administrator</option>
                </select>
                </div>
                <div className="text-center">
                  <button
                    onClick={handleSubmit}
                    type="button"
                    className="btn btn-blue w-50 py-2 theme-btn mx-auto btn-text"
                  >
                    Login
                  </button>
                </div>
              </form>
              {/* <i className='fas fa-chevron-down mr-2'></i> */}
              <hr />
              <div className="auth-option text-center">
                  <p className='text-white fs-7 mt-5'>
                   Copyright &copy; Code14 LABS {new Date().getFullYear()}
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-5 col-lg-8 h-100 auth-background-col">
        <div className="auth-background-holder"></div>
        <div className="auth-background-mask"></div>
      </div>
    </div>
  );
};

export default Login;
