import React from "react";
import "../Modal.css";
// import img from "../assets/img/html-white.png";
import { useNavigate } from "react-router-dom";

const Modal = ({ handleClose, show, image, title, duration, description, id, status, activeCourses }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  const navigate = useNavigate();

  const handleSubmit = async (id) => {
    navigate(`/course/${id}`);
  };

  // Check if the courseId exists in the activeCourses array
  const isActiveCourse = activeCourses.some(course => course.courseid === id);

  return (
    <section className={showHideClassName}>
      <section className="modal-main">
        <div className="top-part">
          <img src={image} className="html-img me-3 mt-1" />
          <div className="course-details">
            <h3 className="course-headings">Course</h3>
            <p className="course-paragraphs">{title}</p>
            <h3 className="course-headings">Duration</h3>
            <p className="course-paragraphs">{duration} mins</p>
          </div>
          <button onClick={handleClose} className="close-button">
            X
          </button>
        </div>
        <div className="description-part">
          <h2 className="description-heading mb-4 mt-5 text-center">Description</h2>
          <p className="description-content">{description}</p>
        </div>
        <div className="text-center">
        <button
            onClick={() => handleSubmit(id)}
            className={`start-button ${isActiveCourse ? "" : "disabled"}`}  // Add active class if it's an active course
            disabled={!isActiveCourse} // Disable the button if it's not an active course
          >
            Start
          </button>
          </div>
      </section>
    </section>
  );
};

export default Modal;
