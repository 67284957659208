import React from "react"
import Card from "./Card"
import Footer from "./Footer"

function Hero({username}) {
    return (
        <div className="main hero--text container mt-5">
                <h4 className="text-center">Hello {username},</h4>
                <h3 className="text-center mb-4 fw-bold">Welcome to your CS LAB</h3>
                <p className="text-center">select a course to start</p>
            <Card/>
            </div>
    )
}

export default Hero;