import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import Nav from "./Nav";
import Footer from "./Footer";
import Hero from "./Hero";

function Landing() {
    
  const navigate  = useNavigate();

  const [loggedInUserName, setLoggedInUserName] = useState("");

  useEffect(() => {
    const token = localStorage.getItem('token');

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': token,
      },
      mode: "cors",
      credentials: "include"
    };

    fetch("https://cslabsapi-b05a5c3c41f0.herokuapp.com/cslabs/v1/teacher/teacherdetails", requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data && data.nomineeFirstName && data.nomineeLastName) {

        const userName = data.nomineeFirstName + " " + data.nomineeLastName;
        setLoggedInUserName(userName);
        console.log("Logged-in User Name:", userName);
        }
        else {
            alert('Log Into your account');
            navigate('/');
        }
      })
      .catch(error => {
        console.error("Error fetching logged-in user details:", error);
        navigate('/');
      });
  }, []);

  return (
    <div>
      <Nav username={loggedInUserName} />
      <Hero username={loggedInUserName}/>
      <Footer />
    </div>
  )
}

export default Landing;
