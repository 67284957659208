import React from 'react'
import Nav from "./Nav"
import "../course.css"
import 'bootstrap/dist/js/bootstrap.js';
import WorkPack from "./WorkPack"
import { useEffect } from 'react';
import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import Test from './CourseDelivery'



function CourseView() {

    const [loggedInUserName, setLoggedInUserName] = useState("");

    const navigate = useNavigate();
    const handleSubmit = async (id) => {
        navigate(`/courseDelivery/${id}`);
      };

  useEffect(() => {
    const token = localStorage.getItem('token');

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': token,
      },
      mode: "cors",
      credentials: "include"
    };

    fetch("https://cslabsapi-b05a5c3c41f0.herokuapp.com/cslabs/v1/teacher/teacherdetails", requestOptions)
      .then(response => response.json())
      .then(data => {
        const userName = data.nomineeFirstName + " " + data.nomineeLastName;
        setLoggedInUserName(userName);
        console.log("Logged-in User Name:", userName);
      })
      .catch(error => {
        console.error("Error fetching logged-in user details:", error);
      });
  }, []);
    // super();
    // this.state = {
    //     Resources: [],
    //   };

    const element = <FontAwesomeIcon icon={faBars} />
    const {id} = useParams();
    // const courseId = '647f21a1bdf3cfec3bccd0f7';
    // const course = {courseId: id}
    console.log(id)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({courseId: id}),
        mode: "cors",
      credentials: "include"
      };
    
    const [Resources, setResources] = useState([]);
    useEffect(() => {
        fetch('https://cslabsapi-b05a5c3c41f0.herokuapp.com/cslabs/v1/course/courseResources',requestOptions)
        .then(Response => Response.json())
        .then(data => {
            const sortedResources = data.sort((a, b) => a.tag - b.tag);
            setResources(sortedResources)
            // console.log(data);
        });
    }, []);

    const request = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ courseId: id}),
        mode: "cors",
      credentials: "include"
      };
    
    const [Details, setDetails] = useState([]);
    useEffect(() => {
        fetch('https://cslabsapi-b05a5c3c41f0.herokuapp.com/cslabs/v1/course/coursedetails',request)
        .then(Response => Response.json())
        .then(data => {
            const sortedDetails = data.sort((a, b) => a.tag - b.tag);
            setDetails(sortedDetails)
            console.log(sortedDetails);

           
        });
    }, []);
    
   
    

    return (
        
        <div>
            <Nav username={loggedInUserName}/>
            <div className="d-flex align-items-start course-container">
                <div 
                    className="nav-tab view--left nav-pills  flex-column " 
                    id="v-pills-tab" 
                    role="tablist"
                    aria-orientation='vertical'
                    >
                    <button class="navbar-toggler icon-btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="icon">{element}</span>
                    </button>
                    <div class="collapse navbar-collapse view" id="navbarSupportedContent">
                        <div>
                        <button
                            className="active ps-2 pb-2 pt-2 button" 
                            id="v-pills-course-tab" 
                            data-bs-toggle="pill" 
                            data-bs-target="#v-pills-course" 
                            type="button" 
                            role="tab" 
                            aria-controls="v-pills-course" 
                            aria-selected="true"
                            >Course Outline</button></div>
                        <div>
                        <button 
                            className=" ps-2 pb-2 pt-2 button" 
                            id="v-pills-workpack-tab" 
                            data-bs-toggle="pill" 
                            data-bs-target="#v-pills-workpack" 
                            type="button" 
                            role="tab" 
                            aria-controls="v-pills-workpack" 
                            aria-selected="false"
                            >Work Packs</button></div>
                
                    </div>
                </div>
                {Details.map( Detail => (
                    <div 
                    className="w-75 view--right flex-column tab-content" 
                    id="v-pills-tabContent">
                    <h6>Course Name</h6>
                    <h3 className="mb-5">{Detail.courseName}</h3>
                    <div 
                        className="tab-pane fade show active" 
                        id="v-pills-course" 
                        role="tabpanel" 
                        aria-labelledby="v-pills-course-tab" 
                        tabindex="0">
                        
                        <div className="d-flex justify-content-between">
                            <h6 className="">Topics</h6>
                            <h6 className=""> Time</h6>
                        </div>
                        <div className='scroll'>
                            {Resources.map(Resource => (
                               
                                <ul class="nav-tab flex-column ps-0 mt-2 mb-4">
                            <li class="nav-item d-flex justify-content-between">
                                <a class="nav-link" href="#">{Resource.resourceName} </a>

                                <span className="me-2">{Resource.completionTime} mins</span>

                           
                            </li>
                            <hr/>
                             </ul>
                        
                             ))}
                        </div>

                        <div className="text-center mt-1 start">
                            <button className="button" onClick={() => {handleSubmit(id)}}>Start</button>
                        </div>
                    </div>
               
                    <div 
                    className="tab-pane fade work--pack" 
                    id="v-pills-workpack" 
                    role="tabpanel" 
                    aria-labelledby="v-pills-workpack-tab" 
                    tabindex="0">
                        <div className="workpack--hero">
                            <h6 className="text-center">Hello {loggedInUserName},</h6>
                            <h5 className="text-center">These are your workpacks for this course.</h5>
                        </div>
                        <WorkPack/>
                    </div>
                    <footer className="mt-1 text-center mb-1 ">
                        <p className="text-secondary">Copyright &copy; Code14 LABS {new Date().getFullYear()}</p>
                    </footer>
                </div>

))}
            </div>
            
        </div>
    )
}

export default CourseView;


