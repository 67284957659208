import React from "react"

 function Footer() {
    return(
        <footer className="footer text-center ">
            <p className="text-secondary">Copyright &copy; Code14 LABS {new Date().getFullYear()}</p>
        </footer>
    )
}

export default Footer;