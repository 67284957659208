import React, { Component, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react'
// import data from "./data"
import "swiper/swiper.css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import Modal from "./Modal";


class Card extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      courses: [],
      id: (''),
      image: (''),
      title: (''),
      duration: (''),
      desc: (''),
      status: false,
      activeCourses: [],
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {

    const requestOptions = {
        method:'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: "cors",
      credentials: "include"
    }

    fetch("https://cslabsapi-b05a5c3c41f0.herokuapp.com/cslabs/v1/course/all", requestOptions)
      .then(response => response.json())
      .then(data => {
        this.setState({ courses: data });
      })
      .catch(error => {
        console.error("Error fetching courses:", error);
      });

      fetch('https://cslabsapi-b05a5c3c41f0.herokuapp.com/cslabs/v1/course/course/status', requestOptions)
      .then(response => response.json())
      .then(data => {
        // console.log('Activation Status:', data); // This line logs the data 
        const activeCourses = data.filter(course => course.active === true);
        // console.log('Filtered Active Courses:', activeCourses); // Log the actice cources

        this.setState({ activeCourses: activeCourses })
      })
      .catch(error => {
        console.error('Error fetching activation status:', error);
      });

      // console.log(this.state.courses);
      // console.log(this.state.activeCourses);
  }

  showModal = (courseId) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ courseId: courseId }) ,
      mode: "cors",
      credentials: "include"
    };
  
    fetch('https://cslabsapi-b05a5c3c41f0.herokuapp.com/cslabs/v1/course/coursedetails', requestOptions)
      .then(response => response.json())
      .then(details => {
        // console.log('Course Details:', details[0]);
        const course = details[0];
        
        this.setState({
          show: true,
          id: course._id,
          image: course.courseImage,
          title: course.courseName,
          duration: course.courseTime,
          desc: course.courseDescription,
          // status: course.status
          // status: status // Set the status value
        });
  
        // console.log(this.state.desc);
      })
      .catch(error => {
        console.error('Error fetching course details:', error);
      });
  };



  
  

  hideModal = () => {
    this.setState({ show: false });
  };


  render() {
    const {courses} = this.state
    const sortedCourses = courses.sort((a, b) => a.tag - b.tag);


    return (
      <div>
      <Modal show={this.state.show} 
      handleClose={this.hideModal} 
      image={this.state.image} 
      title={this.state.title} 
      description={this.state.desc} 
      duration={this.state.duration} 
      id={this.state.id} 
      status={this.state.status}
      activeCourses={this.state.activeCourses}> 
        <p>Modal</p>
      </Modal>
      <Swiper
        navigation={true}
        slidesPerView={"3"}
        modules={[Navigation]}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          760: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={swiper => console.log(swiper)}
        className="mySwiper mt-5"
      >
        {sortedCourses.map(course => (
          <SwiperSlide key={course._id} className="slide">
            <div className="slide--content">
              <div className="card d-flex flex-column align-items-center bg-white">
                <img src={course.courseImage} className="card--img" alt={course.courseName} />
                <button
                  className='card--button mt-3'
                  onClick={() => this.showModal (course._id)}
                >
                  Course Outline
                </button>
                </div>
                </div>
            </SwiperSlide>
        ))}
      </Swiper> 
        </div>
    ) 
  }
}

export default Card;




















// import React, { Component } from "react";
// import { Swiper, SwiperSlide } from 'swiper/react'
// import data from "./data"
// import "swiper/swiper.css";
// import "swiper/css/navigation";
// import {Navigation} from "swiper";
// import Modal from "./Modal";

// class Card extends Component {
//   constructor() {
//     super();
//     this.state = {
//       show: false
//     };
//     this.showModal = this.showModal.bind(this);
//     this.hideModal = this.hideModal.bind(this);
//   }

//   showModal = () => {
//     this.setState({ show: true });
//   };

//   hideModal = () => {
//     this.setState({ show: false });
//   };


//   render() {
//     return (
//         <div>
//             <Modal show={this.state.show} handleClose={this.hideModal}>
//             <p>Modal</p>
//             </Modal>
//             <Swiper
//                 navigation={true}
//                 slidesPerView={"3"}
//                 modules={[ Navigation]}
//                 breakpoints={{
//                   320: {
//                     slidesPerView: 1,
//                   },
//                   760: {
//                     slidesPerView: 2,

//                   },
//                   1024: {
//                     slidesPerView: 3,

//                   },
//                 }}
//                 onSlideChange ={() => console.log('slide change')}
//                 onSwiper = {swiper => console.log(swiper)}
//                 className="mySwiper mt-5"
//       >
//         {data.map(item => (
//             <SwiperSlide key={item.id}className="slide">
//                 <div className="slide--content">
//                     <div className="card d-flex flex-column align-items-center bg-white">
//                         <img src={`./img/${item.coverImg}`}className="card--img"/>
//                         <button 
//                         className='card--button'
//                         onClick={this.showModal}>
//                             Course Outline
//                         </button> 
//                     </div>
//                 </div>
//             </SwiperSlide>
//         ))}
//       </Swiper> 
//         </div>
//     ) 
//   }
// }

// export default Card;











