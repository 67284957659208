import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css';

import Login from "./components/Login";
import Account from "./components/Account"
import CourseView from "./components/CourseView"
import Delivery from './components/CourseDelivery';

function App() {
  return (
    <Router>
    <Routes>
       <Route path='/' element={<Login />} />   
       <Route path='/dashboard' element={<Account />} /> 
       <Route path='/course/:id' element={<CourseView />} /> 
       <Route path='/courseDelivery/:id' element={<Delivery />} /> 
    </Routes>
    </Router>
    
  );
}

export default App;
