import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../Nav.css";

const DropdownContent = () => {

    

    const navigate  = useNavigate();
    const handleLogout = () => {
        // Make a POST request to the API endpoint
        fetch("https://cslabsapi-b05a5c3c41f0.herokuapp.com/cslabs/v1/logout", {
            method: "POST",
            mode: "cors",
            credentials: "include",
        })
        .then((response) => {
            // Handle the response
            console.log(response);
            if (response.ok) {
            // Redirect to the login page
            navigate("/");
            } else {
                throw new Error("Logout failed");
            }
        })
        .catch((error) => {
            console.error("Logout error:", error);
        });
    };



    return (
        <div className="dropdown-content">
        <Button className="btn btn-danger dp-button col-12" onClick={handleLogout}>
            Logout
        </Button>
        </div>
    );
};

export default DropdownContent;
