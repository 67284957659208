import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import TaskModal from "./TaskModal.js";
import { useParams } from "react-router-dom";
import '../course.css'

const WorkPack = () => {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [workpacks, setWorkpacks] = useState([]);
  const [selectedWorkpack, setSelectedWorkpack] = useState(null);

  const showModal = () => {
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  const fetchWorkpackDetails = async (workpackId) => {
    try {
      const response = await fetch(
        "https://cslabsapi-b05a5c3c41f0.herokuapp.com/cslabs/v1/course/courseworkpack/details",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ workpackId }),
          mode: "cors",
          credentials: "include"
        }
      );
      const data = await response.json();
      setSelectedWorkpack(data[0]);
    } catch (error) {
      console.error("Error fetching workpack details:", error);
    }
  };

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ courseId: id }),
      mode: "cors",
      credentials: "include"
    };

    fetch("https://cslabsapi-b05a5c3c41f0.herokuapp.com/cslabs/v1/course/courseworkpack", requestOptions)
      .then(response => response.json())
      .then(data => {
        setWorkpacks(data);
      })
      .catch(error => {
        console.error("Error fetching workpacks:", error);
      });
  }, [id]);

  const handleReadMore = (workpackId) => {
    fetchWorkpackDetails(workpackId);
    showModal();
  };

  const sortedWorkpacks = workpacks.sort((a, b) => a.tag - b.tag);

  return (
    <main>
      <TaskModal show={show} handleClose={hideModal} workpack={selectedWorkpack} courseId={id} />

      <Swiper
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
        slidesPerView={3}
        breakpoints={{
          320: { slidesPerView: 1 },
          760: { slidesPerView: 2 },
          1024: { slidesPerView: 3 }
        }}
      >
        {sortedWorkpacks.map(workpack => (
          <SwiperSlide key={workpack._id} className="packslide">
            <div className="slide-content mb-4">
              <div className="slide-card bg-white ">
                <img
                  src={workpack.workpackCoverImage}
                  className="workpack--cardImg"
                  alt={workpack.workpackName}
                />
                <p className="py-1 workpackName">{workpack.workpackName}</p>
                <button
                  type="button"
                  onClick={() => handleReadMore(workpack._id)}
                  className="card--button workpack-cardBtn"
                >
                  Read More
                </button>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </main>
  );
};

export default WorkPack;
