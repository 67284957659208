import React, { useState, useEffect } from "react";
import "../Modal.css";
import html from "../assets/img/html-white.png";

const TaskModal = ({ handleClose, show, workpack, courseId }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  const [clicked, setClicked] = useState(false);
  const [submissionLink, setSubmissionLink] = useState("");
  const [submissionError, setSubmissionError] = useState("");
  const [submissionDone, setSubmissionDone] = useState(false);
  const [uploadVisible, setUploadVisible] = useState(true);

  useEffect(() => {
    const checkSubmission = async () => {
      const url = "https://cslabsapi-b05a5c3c41f0.herokuapp.com/cslabs/v1/course/courseworkpack/submissionCheck";
      const requestBody = {
        workpackId: workpack?._id,
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
          mode: "cors",
          credentials: "include",
        });
        const data = await response.json();
        console.log(data);
        setSubmissionDone(data);
      } catch (error) {
        console.error("Submission check error:", error);
      }
    };

    checkSubmission();
  }, [workpack?._id]);

  const submittedWorkpack = async () => {
    // Validate URL format using regex
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    if (!urlRegex.test(submissionLink)) {
      setSubmissionError("Invalid URL format. Please enter a valid URL.");
      return;
    }

    const url = "https://cslabsapi-b05a5c3c41f0.herokuapp.com/cslabs/v1/course/courseworkpack/submit";
    const requestBody = {
      courseId,
      workpackId: workpack._id,
      submissionLink,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
        mode: "cors",
        credentials: "include",
      });
      const data = await response.json();
      console.log(data);
      if (data.msg) {
        alert(data.msg);
        handleClose(); // Close the modal
        setClicked(false); // Reset clicked state
        setSubmissionLink(""); // Reset submissionLink state
        setSubmissionError(""); // Reset submissionError state
        setUploadVisible(false); // Hide the upload button
      } else {
        alert("Something went wrong");
      }

      console.log(data);
    } catch (error) {
      console.error("Submission error:", error);
    }
  };

  const handleDownloadWorkpack = () => {
    if (workpack && workpack.workpackLink) {
      window.open(workpack.workpackLink, "_blank");
    }
  };

  const handleSubmissionLinkChange = (event) => {
    setSubmissionLink(event.target.value);
    setSubmissionError("");
  };
  // d-flex justify-content-start pt-1
  return (
    <>
      <div className={showHideClassName}>
        <section className=" modal-main flex-column justify-content-center">
        <div className="outer">
              <button className="outer--button pt-2" type="button" onClick={handleClose}>
                X
              </button>
            </div>
          <div className=" background" style={{backgroundImage:`url(${workpack?.workpackCoverImage})`, position:"relative" }}>
            <div className="overlay"></div>
            {/* <img src={workpack?.workpackCoverImage} className="img" alt="HTML Icon" /> */}
            <div className="flex-column align-items-center justify-content-center">
              <p className="small-txt">Workpack</p>
              <p className="txt center">{workpack?.workpackName}</p>
              <p className="small-txt">Duration</p>
              <p className="txt text-center">{workpack?.completionTime} hrs</p>
            </div>
            {/* <div className="outer">
              <button className="outer--button pt-2" type="button" onClick={handleClose}>
                X
              </button>
            </div> */}
          </div>
          <div className="">
            <h3 className="mb-4 h3 mt-4 description">Description</h3>
            <p className="mb-4 text">{workpack?.workpackDescription}</p>
          </div>
          <div className="text-center d-flex flex-column align-items-center">
            <button className="mb-3 text-center task--button" onClick={handleDownloadWorkpack}>
              Download Workpack
            </button>

            {!submissionDone && (
              <>
                {clicked ? (
                  <button className="text-center close--button  mb-2" onClick={() => setClicked(false)}>
                    Close
                  </button>
                ) : (
                  <button
                    className="text-center task--button mb-2"
                    type="button"
                    onClick={() => setClicked(true)}
                  >
                    Upload Submission
                  </button>
                )}

                {clicked && (
                  <div id="inputField" className="upload">
                    <h3>Submit a link to a zip folder with your work on Google Drive.</h3>
                    <h3>Please note once submitted, entry cannot be changed.</h3>
                    <input type="text" value={submissionLink} onChange={handleSubmissionLinkChange} />
                    {submissionError && <p className="error-message">{submissionError}</p>}
                  </div>
                )}

                {clicked && (
                  <button className="text-center task--button mb-2 mt-2" type="button" onClick={submittedWorkpack}>
                    Submit
                  </button>
                )}
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default TaskModal;
