import React from "react";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import logo from "../assets/img/CSLabLogo.png";
import setting from "../assets/img/settings.png";
import DropdownContent from "./DropdownContent";
import "../Nav.css"
import { Link } from "react-router-dom";


function Nav({username}) {
console.log('username: ' + username);

    const [showDropdown, setShowDropdown] =useState(false);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    }

    return(
        <>
        <nav className="nav w-100">
                <Link to="/dashboard" className="nav--link">
                    <img src={logo} alt="CSLabs Logo" className="nav--img ms-4" />
                </Link>
                <div className="nav--text  pt-3 desktop-margin mobile-margin-off">
                    <button className="me-1 text-white usernameButton" onClick={toggleDropdown}>{username}</button>
                        {showDropdown && <DropdownContent />}
                    <img src={setting} onClick={toggleDropdown}/>
                </div>
        </nav>
    
    </>
    )
}

export default Nav;