import React from "react";
import Nav from "./Nav";
import { useState } from 'react';
import { useEffect } from "react";
import '../CourseDelivery.css';
import jsonData from "../html-course.json";


function Delivery () {
    const [loggedInUserName, setLoggedInUserName] = useState("");
    const [display, setDisplay] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    // const items = ['Item1 ', 'Item2 ', 'Item3 ', 'Item4 '];
    const items = jsonData;
    // console.log(items.title);
  

    const handlePrevItem = () => {
        if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
        }
    };

    const handleNextItem = () => {
      if (currentIndex < items.length - 1) {
        setCurrentIndex(currentIndex + 1);
      }
    };
   
    const handleDisplay = (index) => {
      setCurrentIndex(index);
      setDisplay(items[index]);
    };
    



    useEffect (() => {
        const requestOptions = {
            method:"POST",
            headers:{
                "content-type":"application/json"
            },
            mode:"cors",
            credentials:"include"
        };

        fetch("https://cslabsapi-b05a5c3c41f0.herokuapp.com/cslabs/v1/teacher/teacherdetails", requestOptions)
            .then(response => response.json())
            .then(data => {
                const userName = data.nomineeFirstName + " " + data.nomineeLastName;   
                setLoggedInUserName(userName);
            })
            .catch(error => {
                console.error("Error fetching logged-in user details:", error);
              });
    }, [])

    return (
      <div className="body">
        <Nav username={loggedInUserName} />

        <div className="main-area">
            <div className="sidebar">
              <p className="course-title">Introduction to html</p>
              {items.map((item, index) => (
              <button className="sidebar-buttons" onClick={() => handleDisplay(index)} >
                <ul className="sidebar-items" key={index}>
                  <li
                    className={currentIndex === index ? 'active' : ''} 
                    onClick={() => handleDisplay(index)}
                  >
                    {item.title}
                  </li>
                </ul>
              </button>
              ))}
            </div>
          

          <div className="main-view">
              <h3 className="main-view-title">{items[currentIndex].title}</h3>
              <p className= "main-view-body" dangerouslySetInnerHTML={{ __html: items[currentIndex].body }}></p>
          </div>
        </div>

          <div className="footer">
              <div className="footer-content">
                <button className="arrow-button" onClick={handlePrevItem} style={{ marginLeft: 'auto' }}>
                  &lt;
                </button>
                <ul className="footer-items">
                  <li>{items[currentIndex].title}</li>
                </ul>
                <button className="arrow-button" onClick={handleNextItem} style={{ marginRight: 'auto' }}>
                  &gt;
                </button>
              </div>
          </div>
      </div>

    )}

export default Delivery;















// import React, { useState } from "react";
// import Nav from "./Nav";
// import { useEffect } from "react";
// import jsonData from "../html-course.json";
// import "../CourseDelivery.css";

// function Delivery() {
//   const [loggedInUserName, setLoggedInUserName] = useState("");
//   const [display, setDisplay] = useState("");
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [isSidebarOpen, setSidebarOpen] = useState(false); // Add state for sidebar visibility

//   const items = jsonData;

//   const handlePrevItem = () => {
//     if (currentIndex > 0) {
//       setCurrentIndex(currentIndex - 1);
//     }
//   };

//   const handleNextItem = () => {
//     if (currentIndex < items.length - 1) {
//       setCurrentIndex(currentIndex + 1);
//     }
//   };

//   const handleDisplay = (index) => {
//     setCurrentIndex(index);
//     setDisplay(items[index]);
//     setSidebarOpen(false); // Collapse the sidebar when an item is clicked
//   };

//   useEffect(() => {
//     const requestOptions = {
//       method: "POST",
//       headers: {
//         "content-type": "application/json",
//       },
//       mode: "cors",
//       credentials: "include",
//     };

//     fetch("https://cslabsapi-b05a5c3c41f0.herokuapp.com/cslabs/v1/teacher/teacherdetails", requestOptions)
//       .then((response) => response.json())
//       .then((data) => {
//         const userName = data.nomineeFirstName + " " + data.nomineeLastName;
//         setLoggedInUserName(userName);
//       })
//       .catch((error) => {
//         console.error("Error fetching logged-in user details:", error);
//       });
//   }, []);

//   const toggleSidebar = () => {
//     setSidebarOpen(!isSidebarOpen);
//   };

//   return (
//     <div className="body">
//       <Nav username={loggedInUserName} />

//       <div className={`main-area ${isSidebarOpen ? "sidebar-open" : ""}`}>
//         <div className="sidebar">
//           <p className="course-title">Introduction to html</p>
//           {isSidebarOpen &&
//             items.map((item, index) => (
//               <button
//                 className="sidebar-buttons"
//                 onClick={() => handleDisplay(index)}
//                 key={index}
//               >
//                 <ul className="sidebar-items">
//                   <li
//                     className={currentIndex === index ? "active" : ""}
//                     onClick={() => handleDisplay(index)}
//                   >
//                     {item.title}
//                   </li>
//                 </ul>
//               </button>
//             ))}
//         </div>

//         <div className="main-view">
//           <button className="sidebar-toggle" onClick={toggleSidebar}>
//           <span className={`icon ${isSidebarOpen ? "collapsed" : ""}`}>&#9776;</span>
//           </button>
//           <h3 className="main-view-title">{items[currentIndex].title}</h3>
//           <p
//             className="main-view-body"
//             dangerouslySetInnerHTML={{ __html: items[currentIndex].body }}
//           ></p>
//         </div>
//       </div>

//       <div className="footer">
//         <div className="footer-content">
//           <button className="arrow-button" onClick={handlePrevItem} style={{ marginLeft: "auto" }}>
//             &lt;
//           </button>
//           <ul className="footer-items">
//             <li>{items[currentIndex].title}</li>
//           </ul>
//           <button className="arrow-button" onClick={handleNextItem} style={{ marginRight: "auto" }}>
//             &gt;
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Delivery;
